import React, { useState, useEffect } from "react";
import { getSFAuthToken } from "~components/custom/infomeeting/services/infomeeting-service";
import { isGatsbyProduction } from "~components/utils/env-helpers";
import axios from "axios";
import { getAvatarDefaultProfile } from "../students/constants";

const PHOTO_API_URL = "https://hsey-api.azurewebsites.net/api/iec/getIecPhoto";
const DEV_PHOTO_API_URL =
  "https://hsey-api-dev.azurewebsites.net/api/iec/getIecPhoto";

const getProfilePicture = ({
  iecId,
  photoId,
}: {
  iecId: number;
  photoId: number;
}) => {
  return getSFAuthToken().then((authToken) => {
    return axios
      .get(
        `${
          isGatsbyProduction() ? PHOTO_API_URL : DEV_PHOTO_API_URL
        }?iecId=${iecId}&photoId=${photoId}`,
        {
          headers: { Authorization: `bearer ${authToken}` },
        }
      )
      .then((res) => {
        if (JSON.stringify(res?.data).includes("ResourceNotFound")) {
          return getAvatarDefaultProfile();
        }
        return res?.data;
      })
      .catch((err) => console.log(err));
  });
};

export const IecProfilePhoto = ({
  iecId,
  photoId,
}: {
  iecId: number;
  photoId: number;
}) => {
  const [photoUrl, setPhotoUrl] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        await getProfilePicture({ iecId, photoId })
          .then((res) => setPhotoUrl(res))
          .catch((err) => console.log(err));
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, []);

  let attemptLoadAvatar = 0;

  return photoUrl ? (
    <div className="profile">
      <img
        src={photoUrl}
        alt="International Exchange Coordinator"
        onError={({ currentTarget }) => {
          if (attemptLoadAvatar > 5) {
            return false;
          }
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = getAvatarDefaultProfile();
          attemptLoadAvatar++;
          return true;
        }}
      />
    </div>
  ) : null;
};
